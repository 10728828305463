import s from "../Modules/Modules.module.css";
import m from "./CourseEx.module.css"
import {CourseDescription, CourseFilter, PageTitle, SubTitle} from "../Modules/Modules";
import bc from "../../assets/Imgs/BigCourse.png";
import {TeacherBlock} from "./CourseExample1";
import img1 from "../../assets/Imgs/med.jpg";
import {findCommonElement} from "../../App";
import {useSelector} from "react-redux";
import {checkGroup} from "./OpecaCourse1";
import {NavLink} from "react-router-dom";

const MediationCourse4 = () => {
    const user = useSelector((state) => state.user);
    const groupsId = [2]

    return (
        <div className={m.main}>
            <div className={m.coursePage}>
                <div className={m.courseLeft}>
                    <SubTitle title="Медиация и медиативные технологии"/>
                    <PageTitle
                        title="Медиативно-восстановительные технологии в образовательной организации"/>
                    <TeacherBlock name="Анатолий Андреевич Козлов"
                                  position='Начальник отдела медиации ФГБУ "Центр защиты прав и интересов детей"'/>
                    {(window.innerWidth < 976 && (checkGroup(user, groupsId) !== true || user.isAdmin !== true || user.isStaff !== true)) ?
                        <div className={m.courseRight}>
                            <p></p>
                            <BuyWindow/>
                        </div> : <></>
                    }
                    {(window.innerWidth < 976 && (checkGroup(user, groupsId) === true || user.isAdmin === true || user.isStaff === true)) ?
                        <div className={m.courseRight}>
                            <p></p>
                            <CourseFilter filterArray={MC4Array}/>
                        </div> : <></>
                    }
                    <div className={m.courseImg}>
                        <img src={img1} alt=""/>
                    </div>
                    <CourseDescription title="Описание">
                        <CourseDesc/>
                    </CourseDescription>
                    {/*<CourseDescription title="Раздел 1.">*/}
                    {/*    <Razdel/>*/}
                    {/*</CourseDescription>*/}
                    {/*<CourseDescription title="Раздел 2.">*/}
                    {/*</CourseDescription>*/}
                    {/*<CourseDescription title="Календарь">*/}
                    {/*    <CalendarExample/>*/}
                    {/*</CourseDescription>*/}
                </div>
                <div className={m.courseRight}>
                    {
                        (window.innerWidth >= 976 && (checkGroup(user, groupsId) !== true || user.isAdmin !== true || user.isStaff !== true)) ?
                            <BuyWindow/>
                            : <></>
                    }
                    {
                        (window.innerWidth >= 976 && (checkGroup(user, groupsId) === true || user.isAdmin === true || user.isStaff === true)) ?
                            <CourseFilter filterArray={MC4Array}/>
                            : <></>
                    }

                </div>

            </div>
        </div>
    )
}
export default MediationCourse4

const CourseDesc = () => {
    return <div className={s.courseDescription}>
        Форма обучения: очно-заочная, с применением электронного обучения, дистанционных образовательных технологий.
        Программа направлена на совершенствование знаний по темам:
        <ul>
            <li>
                закономерности развития личности;
            </li>
            <li>
                психологические механизмы воспитания;
            </li>
            <li>
                принципы развития нравственного сознания обучающихся.
            </li>
        </ul>
        По завершении обучения научитесь:
        <ul>
            <li>
                анализировать законодательство в установленной сфере и применять на практике нормативные правовые акты;
            </li>
            <li>
                анализировать получаемую информацию и осуществлять ее первичную статистическую обработку;
            </li>
            <li>
                применять медиативные и восстановительные технологии как способ разрешения и профилактики конфликтных
                ситуаций в образовательной среде;
            </li>
            <li>
                конструктивно взаимодействовать с родителями и специалистами, участвующими в образовательном процессе,
                для решения проблем воспитания, обучения и развития детей, предупреждения и разрешения школьных
                конфликтов;
            </li>
            <li>
                описывать конкретные практические случаи;
            </li>
            <li>
                работать в межведомственной команде.
            </li>
        </ul>
        Программа составлена в соответствии с профессиональным стандартом «Педагог (педагогическая деятельность в сфере
        дошкольного, начального общего, основного общего, среднего общего образования) (воспитатель, учитель)».
        <br/>
        К освоению программы допускаются лица, имеющие среднее профессиональное и (или) высшее образование; лица,
        получающие высшее образование.
        <p></p>
        <strong>Цель программы:</strong><br style={{fontSize: "24px"}}/>
        Cовершенствование (получение) новой компетенции, необходимой для профессиональной деятельности, и (или)
        повышение профессионального уровня в рамках имеющейся квалификации.
        <br/>
        Программа предназначена для повышения квалификации работников системы образования.
        <p></p>
        <strong>Программа курса:</strong><br style={{fontSize: "24px"}}/>
        <ol>
            <li>
                Особенности медиации как метода урегулирования споров и конфликтов.
            </li>
            <li>
                Школьный конфликт и медиация.
            </li>
            <li>
                Основополагающие принципы и технологии медиативного и восстановительного подходов.
            </li>
            <li>
                Организационные вопросы интеграции медиации в образовательную организацию.
            </li>
        </ol>
        <p></p>
        <strong>Преподаватели (планируемые):</strong><br style={{fontSize: "24px"}}/>
        <ul>
            <li>
                <b>Анатолий Андреевич Козлов</b>, начальник отдела медиации.
            </li>
        </ul>
        <p></p>
        <strong>Характеристики курса</strong><br style={{fontSize: "24px"}}/>
        Трудоемкость программы: 36 ак. часов <br/>
        Длительность: 8 занятий<br/>
        Учебная нагрузка по курсу: 4 ак. часа в день.<br/>
        Язык курса: русский<br/>
        Удостоверение установленного образца: при успешной сдаче итоговой аттестации слушатели получают удостоверение о
        повышении квалификации на 36 академических часов.
        <p></p>
        {/*<strong>Даты проведения:</strong><br style={{fontSize: "24px"}}/>*/}
        {/*<strong>Период обучения: с 4 июня по 05 июля 2024 г.</strong><br/>*/}
        {/*Даты занятий в 2024 году: 04.06, 06.06, 10.06, 13.06, 18.06, 20.06, 25.06, 27.06, 02.07 - итоговая аттестация/зачет<br/>*/}
        {/*Время: 14:30 - 17:40*/}
    </div>
}

// const Razdel = () => {
//     return <div className={s.courseDescription}>
//         <strong>Правовые основы и направления деятельности органов опеки и попечительства при рассмотрении споров о
//             воспитании
//             детей</strong>
//         <p></p>
//         <div className={s.ref}>
//             <a href="/course1/part1"><strong>Подраздел 1.1</strong><br/>
//                 Правовые основания и направления деятельности органов опеки и попечительства при рассмотрении споров о
//                 воспитании детей (на досудебной стадии, в судебном процессе, в исполнительном производстве)</a>
//         </div>
//         <p></p>
//         <strong>Подраздел 1.2</strong><br/>
//         Учет мнения ребенка при решении вопросов, затрагивающих его права и интересы. Методы и способы выяснения мнения
//         ребенка в случаях, предусмотренных законодательством. Учет психологических, медицинских, социальных факторов при
//         общении с детьми разных возрастных групп при выявлении их мнения
//     </div>
// }
//
// const Zadanie = ({
//                      questions
//                  }) => {
//     return (
//         <div className={s.courseDescription}>
//             {questions.map(el =>
//                 <Question id={el.id} question={el.question} answer={el.answer}/>
//             )}
//             <div className={s.send}>
//                 <a href="/">Отправить ответы</a>
//             </div>
//
//         </div>
//     )
// }
//
// const Question = ({
//                       id, question, answer = null
//                   }) => {
//     return (
//         <div>
//             <div style={{paddingBottom: "20px"}}>
//                 <strong>Вопрос {id}</strong>
//             </div>
//             <div style={{paddingBottom: "20px"}}>
//                 {question}
//             </div>
//             <div className={s.qInput}>
//                 <input style={answer === null ? {backgroundColor: "white"} : {backgroundColor: "#008E4C17"}}
//                        id="questionInput" type="text" placeholder={answer === null ? "Ответ" : answer}/>
//                 <button id="searchButton"
//                         style={answer === null ? {backgroundColor: "#e07b9b"} : {backgroundColor: "#3F5070"}}>{answer === null ? "Ответить" : "Ответ принят"}</button>
//             </div>
//
//         </div>
//     )
// }

const BuyWindow = () => {
    return (
        <div className={m.buy}>
            <div className={m.info}>
                <div style={{fontSize: "18px", paddingBottom: "5px"}}>
                    <strong>Принять участие</strong>
                </div>
                {/*<div style={{fontSize: "16px", paddingBottom: "10px"}}>*/}
                {/*    <span><strong>0 человек</strong></span> прошли курс*/}
                {/*</div>*/}
                <div style={{fontSize: "12px", paddingBottom: "30px", color: "#808080"}}>
                    36 академических часов
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: "10px"
                }}>
                    <div style={{fontSize: "12px", color: "#808080"}}>
                        Стоимость курса
                    </div>
                    <div style={{fontSize: "18px"}}>
                        <strong>15 000 ₽</strong>
                    </div>
                </div>
                <div className={m.buyButton}>
                    <NavLink to="#">Записаться на курс</NavLink>
                </div>
            </div>
        </div>
    )
}

export const MC4Array = [
    {
        title: "Описание курса",
        url: "/mediationcourse4",
    },
    {
        title: "Информация для обучающихся",
        url: "/mediationcourse4/info"
    },
]