import s from "../Modules/Modules.module.css";
import m from "./CourseEx.module.css"
import {CourseDescription, PageTitle, SubTitle} from "../Modules/Modules";
import bc from "../../assets/Imgs/BigCourse.png";
import {TeacherBlock} from "./CourseExample1";
import pdp from "../../assets/Imgs/pdp.jpg"
import {NavLink} from "react-router-dom";

const PdpCourse1 = () => {

    return (
        <div className={m.main}>
            <div className={m.coursePage}>
                <div className={m.courseLeft}>
                    <SubTitle title="Профилактика девиантного поведения"/>
                    <PageTitle
                        title="Формирование ценностей здоровьесбережения и профилактика зависимого поведения обучающихся образовательных организаций"/>
                    <TeacherBlock name="Заева Ольга Вячеславовна"
                                  position='Начальник отдела профилактики девиантного поведения несовершеннолетних ФГБУ "Центр защиты прав и интересов детей"'/>
                    {window.innerWidth < 976 ?
                        <div className={m.courseRight}>
                            <p></p>
                            <BuyWindow/>
                        </div> : <></>
                    }
                    <div className={m.courseImg}>
                        <img src={pdp} alt=""/>
                    </div>
                    <CourseDescription title="Описание">
                        <CourseDesc/>
                    </CourseDescription>
                    {/*<CourseDescription title="Раздел 1.">*/}
                    {/*    <Razdel/>*/}
                    {/*</CourseDescription>*/}
                    {/*<CourseDescription title="Раздел 2.">*/}
                    {/*</CourseDescription>*/}
                    {/*<CourseDescription title="Календарь">*/}
                    {/*    <CalendarExample/>*/}
                    {/*</CourseDescription>*/}
                </div>
                {window.innerWidth >= 976 ?
                    <div className={m.courseRight}>
                        <BuyWindow/>
                    </div> : <></>
                }
            </div>
        </div>
    )
}
export default PdpCourse1


const CourseDesc = () => {
    return <div className={s.courseDescription}>
        Дополнительная профессиональная программа повышения квалификации (далее – программа) направлена на рассмотрение
        вопросов:
        <ul>
            <li>
                нормативное правовое обеспечение организации профилактической деятельности в образовательной организации
                с обучающимися и их родителями (законными представителями);
            </li>
            <li>
                социальная ситуация развития детей и подростков, аддиктивные детерминанты, профилактические мишени;
            </li>
            <li>
                технологии организации профилактической работы в образовательной организации, направленной на
                формирование ценностей ЗОЖ, эмоционального благополучия;
            </li>
            <li>
                профессиональный инструментарий в организации психолого-педагогического сопровождения обучающихся.
            </li>
        </ul>
        Программа предназначена для повышения квалификации педагогов образовательных организаций, специалистов сферы
        профилактики.
        <p></p>
        <strong>Цель программы:</strong><br style={{fontSize: "24px"}}/>
        Целью программы является предоставление знаний о методах и технологиях профилактической работы в образовательных
        организациях с обучающимися и их родителями (законными представителями), направленной на профилактику
        аддиктивного поведения, формирование ценностей и культуры здорового образа жизни, жизнеспособности личности.
        <p></p>
        <strong>Программа курса:</strong><br style={{fontSize: "24px"}}/>
        <ul>
            <li>
                1. Нормативные правовые основания профессиональной деятельности педагогических работников
                образовательной организации в целях формирования культуры и ценностей здоровья обучающихся.
            </li>
            <li>
                2. Модели, методы и технологии организации профилактической работы с обучающимися, ресурсы и
                ограничения.
            </li>
            <li>
                3. Инструментарий профилактики аддикций у обучающихся и организации психолого-педагогического
                сопровождения.
            </li>
            <li>
                4. Воспитание как инструмент профилактики и формирования жизнеспособной (резильентной) личности.
            </li>
        </ul>
        <p></p>
        <strong>Преподаватели (планируемые):</strong><br style={{fontSize: "24px"}}/>
        <ul>
            <li>
                <b>Заева Ольга Вячеславовна</b>, начальник отдела профилактики девиантного поведения несовершеннолетних;
            </li>
            <li>
                <b>Алагуев Михаил Викторович</b>, аналитик I категории.
            </li>
        </ul>
        <p></p>
        <strong>Характеристики курса</strong><br style={{fontSize: "24px"}}/>
        Трудоемкость программы: 20 ак. часов <br/>
        Длительность (недель): 3<br/>
        Учебная нагрузка по курсу: до 2 часов в день<br/>
        Язык курса: русский<br/>
        Удостоверение установленного образца: да.
        <p></p>
        {/*<strong>Период обучения: с 12 сентября по 7 октября 2024 года</strong><br style={{fontSize: "24px"}}/>*/}
        {/*Время проведения занятий:<br/>*/}
        {/*лекционное занятие с 15:30 до 18:40, перерыв с 17:00 до 17:10;<br/>*/}
        {/*практическое занятие с 15:30 до 17:00.*/}


    </div>
}

// const Razdel = () => {
//     return <div className={s.courseDescription}>
//         <strong>Правовые основы и направления деятельности органов опеки и попечительства при рассмотрении споров о
//             воспитании
//             детей</strong>
//         <p></p>
//         <div className={s.ref}>
//             <a href="/course1/part1"><strong>Подраздел 1.1</strong><br/>
//                 Правовые основания и направления деятельности органов опеки и попечительства при рассмотрении споров о
//                 воспитании детей (на досудебной стадии, в судебном процессе, в исполнительном производстве)</a>
//         </div>
//         <p></p>
//         <strong>Подраздел 1.2</strong><br/>
//         Учет мнения ребенка при решении вопросов, затрагивающих его права и интересы. Методы и способы выяснения мнения
//         ребенка в случаях, предусмотренных законодательством. Учет психологических, медицинских, социальных факторов при
//         общении с детьми разных возрастных групп при выявлении их мнения
//     </div>
// }
//
// const Zadanie = ({
//                      questions
//                  }) => {
//     return (
//         <div className={s.courseDescription}>
//             {questions.map(el =>
//                 <Question id={el.id} question={el.question} answer={el.answer}/>
//             )}
//             <div className={s.send}>
//                 <a href="/">Отправить ответы</a>
//             </div>
//
//         </div>
//     )
// }
//
// const Question = ({
//                       id, question, answer = null
//                   }) => {
//     return (
//         <div>
//             <div style={{paddingBottom: "20px"}}>
//                 <strong>Вопрос {id}</strong>
//             </div>
//             <div style={{paddingBottom: "20px"}}>
//                 {question}
//             </div>
//             <div className={s.qInput}>
//                 <input style={answer === null ? {backgroundColor: "white"} : {backgroundColor: "#008E4C17"}}
//                        id="questionInput" type="text" placeholder={answer === null ? "Ответ" : answer}/>
//                 <button id="searchButton"
//                         style={answer === null ? {backgroundColor: "#e07b9b"} : {backgroundColor: "#3F5070"}}>{answer === null ? "Ответить" : "Ответ принят"}</button>
//             </div>
//
//         </div>
//     )
// }

const BuyWindow = () => {
    return (
        <div className={m.buy}>
            <div className={m.info}>
                <div style={{fontSize: "18px", paddingBottom: "5px"}}>
                    <strong>Принять участие</strong>
                </div>
                {/*<div style={{fontSize: "16px", paddingBottom: "10px"}}>*/}
                {/*    <span><strong>0 человек</strong></span> прошли курс*/}
                {/*</div>*/}
                <div style={{fontSize: "12px", paddingBottom: "30px", color: "#808080"}}>
                    20 академических часов
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: "10px"
                }}>
                    <div style={{fontSize: "12px", color: "#808080"}}>
                        Стоимость курса
                    </div>
                    <div style={{fontSize: "18px"}}>
                        <strong>10 000 ₽</strong>
                    </div>
                </div>
                <div className={m.buyButton}>
                    <NavLink to="#">Записаться на курс</NavLink>
                </div>
            </div>
        </div>
    )
}

