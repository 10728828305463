import s from "../Modules/Modules.module.css";
import m from "./CourseEx.module.css"
import {CourseDescription, CourseFilter, Filter, PageTitle, SubTitle} from "../Modules/Modules";
import bc from "../../assets/Imgs/BigCourse.png";
import img1 from "../../assets/Imgs/med.jpg"
import {TeacherBlock} from "./CourseExample1";
import {findCommonElement} from "../../App";
import {useSelector} from "react-redux";
import {checkGroup} from "./OpecaCourse1";
import {NavLink} from "react-router-dom";

const MediationCourse2 = () => {
    const user = useSelector((state) => state.user);
    const groupsId = [4]


    return (
        <div className={m.main}>
            <div className={m.coursePage}>
                <div className={m.courseLeft}>
                    <SubTitle title="Медиация"/>
                    <PageTitle
                        title="Практикум начинающего медиатора: учимся на кейсах"/>
                    <TeacherBlock name="Марина Викторовна Быкова"
                                  position='Аналитик отдела медиации ФГБУ "Центр защиты прав и интересов детей"'/>
                    {(window.innerWidth < 976 && (checkGroup(user, groupsId) !== true || user.isAdmin !== true || user.isStaff !== true)) ?
                        <div className={m.courseRight}>
                            <p></p>
                            <BuyWindow2/>
                        </div> : <></>
                    }
                    {(window.innerWidth < 976 && (checkGroup(user, groupsId) === true || user.isAdmin === true || user.isStaff === true)) ?
                        <div className={m.courseRight}>
                            <p></p>
                            <CourseFilter filterArray={MC2Array}/>
                        </div> : <></>
                    }


                    <div className={m.courseImg}>
                        <img src={img1} alt=""/>
                    </div>
                    <CourseDescription title="Описание">
                        <CourseDesc/>
                    </CourseDescription>
                    {/*<CourseDescription title="Раздел 1.">*/}
                    {/*    <Razdel/>*/}
                    {/*</CourseDescription>*/}
                    {/*<CourseDescription title="Раздел 2.">*/}
                    {/*</CourseDescription>*/}
                    {/*<CourseDescription title="Календарь">*/}
                    {/*    <CalendarExample/>*/}
                    {/*</CourseDescription>*/}
                </div>
                <div className={m.courseRight}>
                    {
                        (window.innerWidth >= 976 && (checkGroup(user, groupsId) !== true || user.isAdmin !== true || user.isStaff !== true)) ?
                            <BuyWindow2/>
                            : <></>
                    }
                    {
                        (window.innerWidth >= 976 && (checkGroup(user, groupsId) === true || user.isAdmin === true || user.isStaff === true)) ?
                            <CourseFilter filterArray={MC2Array}/>
                         : <></>
                    }

                </div>




            </div>
        </div>
    )
}
export default MediationCourse2


const CourseDesc = () => {
    return <div className={s.courseDescription}>
        Специалисты в области медиации, прошедшие обучение, но мало или пока не практикующие (группа до 12 человек).
        <p></p>
        <strong>Цель программы:</strong><br style={{fontSize: "24px"}}/>
        Совершенствование профессиональной компетентности специалиста в области медиации в соответствии с требованиями
        профессионального стандарта «Специалист в области медиации (медиатор)».
        <br/>
        Программа включает в себя интеграцию теоретических знаний и отработку техник и инструментов медиатора в рамках
        практических занятиях по работе с конфликтными ситуациями (кейсами) на уровне начинающего специалиста для
        отработки навыков, что предполагает активное участие слушателей.
        <p></p>
        {/*<strong>Период проведения:</strong><br style={{fontSize: "24px"}}/>*/}
        {/*1 группа:<br/>*/}
        {/*<strong>Период обучения: с 08 апреля по 24 мая 2024 г.</strong><br/>*/}
        {/*Даты занятий: 08.04, 15.04, 22.04, 13.05, 20.05 - итоговая аттестация/зачет.<br/>*/}
        {/*Время: 18:00-21:10*/}
        {/*<p></p>*/}
    </div>
}

// const Razdel = () => {
//     return <div className={s.courseDescription}>
//         <strong>Правовые основы и направления деятельности органов опеки и попечительства при рассмотрении споров о
//             воспитании
//             детей</strong>
//         <p></p>
//         <div className={s.ref}>
//             <a href="/course1/part1"><strong>Подраздел 1.1</strong><br/>
//                 Правовые основания и направления деятельности органов опеки и попечительства при рассмотрении споров о
//                 воспитании детей (на досудебной стадии, в судебном процессе, в исполнительном производстве)</a>
//         </div>
//         <p></p>
//         <strong>Подраздел 1.2</strong><br/>
//         Учет мнения ребенка при решении вопросов, затрагивающих его права и интересы. Методы и способы выяснения мнения
//         ребенка в случаях, предусмотренных законодательством. Учет психологических, медицинских, социальных факторов при
//         общении с детьми разных возрастных групп при выявлении их мнения
//     </div>
// }
//
// const Zadanie = ({
//                      questions
//                  }) => {
//     return (
//         <div className={s.courseDescription}>
//             {questions.map(el =>
//                 <Question id={el.id} question={el.question} answer={el.answer}/>
//             )}
//             <div className={s.send}>
//                 <a href="/">Отправить ответы</a>
//             </div>
//
//         </div>
//     )
// }
//
// const Question = ({
//                       id, question, answer = null
//                   }) => {
//     return (
//         <div>
//             <div style={{paddingBottom: "20px"}}>
//                 <strong>Вопрос {id}</strong>
//             </div>
//             <div style={{paddingBottom: "20px"}}>
//                 {question}
//             </div>
//             <div className={s.qInput}>
//                 <input style={answer === null ? {backgroundColor: "white"} : {backgroundColor: "#008E4C17"}}
//                        id="questionInput" type="text" placeholder={answer === null ? "Ответ" : answer}/>
//                 <button id="searchButton"
//                         style={answer === null ? {backgroundColor: "#e07b9b"} : {backgroundColor: "#3F5070"}}>{answer === null ? "Ответить" : "Ответ принят"}</button>
//             </div>
//
//         </div>
//     )
// }

const BuyWindow = () => {
    return (
        <div className={m.buy}>
            <div className={m.info}>
                <div style={{fontSize: "18px", paddingBottom: "5px"}}>
                    <strong>Принять участие</strong>
                </div>
                {/*<div style={{fontSize: "16px", paddingBottom: "10px"}}>*/}
                {/*    <span><strong>0 человек</strong></span> прошли курс*/}
                {/*</div>*/}
                <div style={{fontSize: "12px", paddingBottom: "30px", color: "#808080"}}>
                    20 академических часов
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: "10px"
                }}>
                    <div style={{fontSize: "12px", color: "#808080"}}>
                        Стоимость курса
                    </div>
                    <div style={{fontSize: "18px"}}>
                        <strong>10 000 ₽</strong>
                    </div>
                </div>
                <div className={m.buyButton}>
                    <NavLink to="#">Записаться на курс</NavLink>
                </div>
            </div>
        </div>
    )
}
const BuyWindow2 = () => {
    return (
        <div className={m.buy}>
            <div className={m.info}>
                <div style={{fontSize: "18px", paddingBottom: "5px", textAlign: "center"}}>
                    <strong>Регистрация закрыта!</strong>
                </div>
            </div>
        </div>
    )
}

export const MC2Array = [
    {
        title: "Описание курса",
        url: "/mediationcourse2",
    },
    {
        title: "Информация для обучающихся",
        url: "/mediationcourse2/info"
    },
]